import logo from './logo.svg';

import logoRight from './logo-right.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
         <span className={"logo"}>
          PAIR</span>
          <img src={logo} className="logo-left" alt="logo" />
          <img src={logoRight} className="logo-right" alt="logo" />
     <span className={"logo"}>
          WING</span>


      </header>
    </div>
  );
}

export default App;
